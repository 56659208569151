<template>
  <div>
    <div class="vx-row my-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="selectExample"
          v-model="selected_territory"
          :options="fetch_territories"
          @select="onSelectTerritory"
          placeholder=" Type to search"
          track-by="id"
          label="name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title"
                >{{ dt.option.code }} - {{ dt.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title"
                >{{ dt.option.code }} - {{ dt.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Salesman</label>
        <multiselect
          class="selectExample"
          v-model="selected_salesman"
          :options="option_salesman"
          :multiple="true"
          @search-change="onSearchSalesman"
          placeholder=" Type to search"
          track-by="id"
          :custom-label="customLabelSalesman"
        />
      </div>
    </div>
    <!-- customer -->
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Customer</label>
        <multiselect
          class="selectExample"
          v-model="selected_customer"
          :options="fetch_customers"
          @search-change="fetchCustomer"
          placeholder=" Type to search"
          :multiple="true"
          track-by="id"
          label="name"
          :custom-label="customLabelCustomer"
        />
      </div>
    </div>
    <!-- supplier filter -->
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Supplier</label>
        <multiselect
          class="selectExample"
          v-model="selected_supplier"
          :options="option_supplier"
          @search-change="onSearchSupplier"
          placeholder=" Type to search"
          :multiple="true"
          track-by="id"
          label="name"
          :custom-label="customLabelSupplier"
        />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label>Periode</label>
        <date-range-picker
          style="min-height: 40px"
          class="w-full"
          ref="picker"
          opens="center"
          :ranges="false"
          :locale-data="{ firstDay: 1, format: 'dd Mon yyyy' }"
          :autoApply="true"
          v-model="daterange"
          :minDate="minDate"
          :maxDate="maxDate"
          @start-selection="onSelectStartDatePicker"
        >
          <template v-slot:input="picker">
            {{ dateFormat(picker.startDate) }} -
            {{ dateFormat(picker.endDate) }}
          </template>
        </date-range-picker>
      </div>
    </div>

    <div class="vx-row mt-2 ml-2">
      <vs-button
        class="ml-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleFilter()"
        >Browse</vs-button
      >

      <!-- export -->
      <vs-button
        class="ml-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-download"
        @click="handleExport()"
        >Export</vs-button
      >

      <!-- reset -->
      <vs-button
        class="ml-2"
        color="danger"
        type="filled"
        icon-pack="feather"
        icon="icon-refresh-cw"
        @click="handleReset()"
        >Reset</vs-button
      >
    </div>

    <div class="">
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.length"
        @search="handleSearch"
        :buttonSearch="true"
        @change-page="handleChangePage"
      >
        <template slot="thead">
          <vs-th>Sales</vs-th>
          <vs-th>Customer</vs-th>
          <vs-th>Customer Ship To</vs-th>
          <vs-th>Item Name</vs-th>
          <vs-th>Unit Name</vs-th>
          <vs-th>Qty</vs-th>
          <vs-th>Expired date</vs-th>
          <vs-th>Batch</vs-th>
          <vs-th>Serial</vs-th>
          <vs-th>Created At</vs-th>
          <!-- <vs-th>Action</vs-th> -->
        </template>
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{
              tr.sales_personal_id_number + " - " + tr.sales_personal_name
            }}</vs-td>
            <vs-td>{{ tr.customer_code + " - " + tr.customer_name }}</vs-td>
            <vs-td>{{ tr.territory_ship_to_name }}</vs-td>
            <vs-td>{{ data[indextr].item_name }}</vs-td>
            <vs-td>{{ data[indextr].unit_name }}</vs-td>
            <vs-td>
              {{ data[indextr].qty }}
            </vs-td>
            <vs-td>
              {{ data[indextr].expired_date | formatDate }}
            </vs-td>
            <vs-td>
              {{ data[indextr].batch }}
            </vs-td>
            <vs-td>
              {{ data[indextr].serial }}
            </vs-td>
            <vs-td>
              {{ data[indextr].created_at }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    DateRangePicker,
  },
  data() {
    return {
      table: this.tableDefaultState(),

      // select territory
      fetch_territories: [],
      selected_territory: [],

      // select customer
      fetch_customers: [],
      selected_customer: [],

      option_salesman: [],
      selected_salesman: [],

      // supplier
      option_supplier: [],
      selected_supplier: [],

      // filter data
      filter_data: {
        territory_id: null,
        sales_personal_id: null,
        company_id: null,
      },

      // date range
      daterange: {
        startDate: null,
        endDate: null,
      },
      // set 5 years ago
      minDate: moment().subtract(5, "years"),
      maxDate: moment(),
    };
  },
  methods: {
    customLabelSupplier(option) {
      return option.code + " - " + option.name;
    },
    onSearchSupplier(query) {
      this.fetchSupplier({ search: query });
    },
    fetchSupplier(params) {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/supplier", {
          params: params,
        })
        .then((response) => {
          if (response.data.records != null) {
            // add default selected option is all in index 0
            this.option_supplier = [
              {
                id: null,
                code: "All",
                name: "All",
              },
            ];
            this.option_supplier = this.option_supplier.concat(
              response.data.records
            );
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleReset() {
      // select territory
      this.fetch_territories = [];
      this.selected_territory = [];

      // select customer
      this.fetch_customers = [];
      this.selected_customer = [];

      this.option_salesman = [];
      this.selected_salesman = [];

      // supplier
      this.option_supplier = [];
      this.selected_supplier = [];

      // filter data
      this.filter_data = {
        territory_id: null,
        sales_personal_id: null,
        company_id: null,
      };

      // date range
      this.daterange = {
        startDate: null,
        endDate: null,
      };
      this.minDate = moment().subtract(5, "years");
      this.maxDate = moment();
    },
    handleExport() {
      this.$vs.loading();
      if (this.daterange.startDate == null || this.daterange.endDate == null) {
        this.$vs.notify({
          title: "Warning",
          text: "Please select date range",
          color: "warning",
        });
        this.$vs.loading.close();
        return;
      }
      let params = {
        customer_ids: this.selected_customer.map((item) => item.customer_id),
        sales_personal_ids: this.selected_salesman.map(
          (item) => item.personal_id
        ),
        is_excel: true,
      };

      if (this.daterange.startDate != null && this.daterange.endDate != null) {
        let start_date = moment(this.daterange.startDate).format("YYYY-MM-DD");
        let end_date = moment(this.daterange.endDate).format("YYYY-MM-DD");

        params = {
          ...params,
          daterange: `${start_date},${end_date}`,
        };
      }

      if (this.selected_territory != null) {
        params = {
          ...params,
          territory_id: this.selected_territory.id,
        };
      }

      params.data = null;

      params = this.trimValidation(params);
      // remove params data

      const currentDate = `${moment().format("YYYY-MM-DD")}`;
      var fileTitle = `REPORT_STOCK_OUTLET_${currentDate}`;
      moment(this.startDate).format("YYYY-MM-DD") +
        this.$http
          .get(`api/sfa/v1/adjustment-stock-item/public/export`, {
            params: params,
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code != 200) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              // var fileURL = window.URL.createObjectURL(new Blob([resp]));
              // var fileLink = document.createElement("a");
              // fileLink.href = fileURL;

              // fileLink.setAttribute("download", fileTitle + ".xlsx");
              // document.body.appendChild(fileLink);
              // fileLink.click();

              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
            }
          });
    },
    customLabelCustomer(option) {
      if (option.name) {
        return `${option.name}`;
      }
      return "";
    },
    // salesman
    customLabelSalesman(option) {
      if (option.personal) {
        return `${option.personal.name}`;
      }
      return "";
    },
    onSearchSalesman(query) {
      this.fetchSalesman({
        territory_id: this.selected_territory.id,
        search: query,
      });
    },
    fetchSalesman(params) {
      this.$vs.loading();
      this.$http.get(`/api/sfa/v1/personal-territory`, { params: params }).then(
        (response) => {
          // add default selected option is all in index 0
          this.option_salesman = [
            {
              personal_id: null,
              personal: {
                name: "All",
              },
            },
          ];
          this.option_salesman = this.option_salesman.concat(
            response.data.records
          );
          this.$vs.loading.close();
        },
        (error) => {
          this.$vs.loading.close();
        }
      );
    },
    trimValidation(data) {
      // remove key if has value is null or empty or undefined
      Object.keys(data).forEach((key) => {
        if (data[key] == null || data[key] == "" || data[key] == undefined) {
          delete data[key];
        }
      });

      return data;
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      if (this.table.total > 0) {
        this.reloadTableCustomerStock();
      }
    },
    handleChangePage(page) {
      this.table.page = page;
      if (this.table.total > 0) {
        this.reloadTableCustomerStock();
      }
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      if (this.table.total > 0) {
        this.reloadTableCustomerStock();
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      if (this.table.total > 0) {
        this.reloadTableCustomerStock();
      }
    },
    async reloadTableCustomerStock() {
      this.$vs.loading();
      let params = {
        customer_ids: this.selected_customer.map((item) => item.customer_id),
        sales_personal_ids: this.selected_salesman.map(
          (item) => item.personal_id
        ),
        ...this.table,
      };

      if (this.daterange.startDate != null && this.daterange.endDate != null) {
        let start_date = moment(this.daterange.startDate).format("YYYY-MM-DD");
        let end_date = moment(this.daterange.endDate).format("YYYY-MM-DD");

        params = {
          ...params,
          daterange: `${start_date},${end_date}`,
        };
      }

      if (this.selected_supplier.length > 0) {
        params = {
          ...params,
          supplier_ids: this.selected_supplier.map((item) => item.id),
        };
      }

      if (this.selected_territory != null) {
        params = {
          ...params,
          territory_id: this.selected_territory.id,
        };
      }

      params.data = null;

      // check if params has value undefined or null
      params = this.trimValidation(params);

      let results = await this.$http.get(
        `/api/sfa/v1/adjustment-stock-item/public/export`,
        {
          params: params,
        }
      );

      if (results.code == 200) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Success",
          text: "Data is ready",
          color: "success",
        });
        if (results.data.records != null) {
          this.table.data = results.data.records;
        } else {
          this.table.data = [];
        }
      } else {
        this.table.data = [];
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: "Data is not ready",
          color: "danger",
        });
      }

      this.table.total = results.data.total_record;
      this.table.totalPage = results.data.total_page;
      this.table.totalSearch = results.data.total_record_search;
      this.table.length = results.data.total_record_per_page;
      this.setStartEnd();
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    async handleFilter() {
      await this.reloadTableCustomerStock();
    },
    async fetchTerritory(query, id) {
      this.$vs.loading();

      let params = {
        length: 0,
        search: query,
        order: "code",
        sort: "asc",
        type: "Sales",
      };

      let res = await this.$http.get(
        "/api/oms/v1/setting-user/territory-master",
        {
          params: params,
        }
      );

      if (res.code == 200) {
        this.fetch_territories = res.data.records;
      }
      this.$vs.loading.close();
    },
    async fetchCustomer(query) {
      const territory_id = this.selected_territory.id;
      if (territory_id == null) {
        this.fetch_customers = [];
        return;
      }

      this.$vs.loading();

      let params = {
        length: 10,
        territory_id: territory_id,
        search: query,
      };

      let res = await this.$http.get("/api/sfa/v1/customer-address/list", {
        params: params,
      });

      if (res.code == 200) {
        if (res.data.records == null) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "No data found",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }

        // add default selected option is all in index 0
        this.fetch_customers = [
          {
            name: "All",
          },
        ];
        this.fetch_customers = this.fetch_customers.concat(res.data.records);
      }

      this.$vs.loading.close();
    },
    async onSelectTerritory(data) {
      this.selected_territory = data;
      this.fetchCustomer();
      this.fetchSalesman({
        territory_id: this.selected_territory.id,
      });
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    onSelectStartDatePicker(value) {
      let setDate = moment(value).format("YYYY-MM-DD");
      // set min date -7 & max date +7
      this.minDate = moment(setDate).subtract(7, "days").format("YYYY-MM-DD");
      this.maxDate = moment(setDate).add(7, "days").format("YYYY-MM-DD");
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
  async mounted() {
    await this.fetchTerritory();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
